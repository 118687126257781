import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import React, { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Timestamp } from 'firebase/firestore';
import { Button } from '../ui/button';
import { Calendar } from '../ui/calendar';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Textarea } from '../ui/textarea';
import { format, parse, set } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import {
  Event,
  Sponsor,
  createEvent,
  updateEvent,
  Cohost,
  EventPerson,
} from '../../services/eventsService';
import { FaTrash } from 'react-icons/fa';

import { DateTime } from 'luxon';


// Utility function to create a Date object from the input strings using Luxon
function toUTCDate(dateString, timeString) {
  // Parse the date and time in PST and then convert to UTC
  const date = DateTime.fromISO(`${dateString}T${timeString}`, { zone: 'America/Los_Angeles' })
    .setZone('utc')
    .toJSDate();

  return date;
}

function formatDateAndTime(timestamp) {
  // Interpret the timestamp directly as a UTC DateTime using Luxon
  const utcDateTime = DateTime.fromISO(timestamp, { zone: 'utc' });

  // Convert from UTC to PST (America/Los_Angeles)
  const pstDateTime = utcDateTime.setZone('America/Los_Angeles');

  // Format the PST date and time
  return {
    formattedDate: pstDateTime.toFormat('yyyy-MM-dd'),
    formattedTime: pstDateTime.toFormat('HH:mm'),
  };
}

const CreateEventDialog = ({ isOpen, onClose, event = null }) => {
  const [eventName, setEventName] = useState('');
  const [eventSlug, setEventSlug] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventTime, setEventTime] = useState('10:00');
  const [eventLocation, setEventLocation] = useState('AGI house, 1868 Floribunda Ave, Hillsborough, CA 94010');
  const [eventDescription, setEventDescription] = useState('');
  const [speakersUpdated, setSpeakersUpdated] = useState<EventPerson[]>([]);
  const [judgesUpdated, setJudgesUpdated] = useState<EventPerson[]>([]);
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);
  const [cohosts, setCohosts] = useState<Cohost[]>([]);
  const [eventImageUrl, setEventImageUrl] = useState('');
  const [eventType, setEventType] = useState('hackathon');
  const [eventAccess, setEventAccess] = useState('admin');
  const [questions, setQuestions] = useState('');
  const [requireDefaultQuestions, setRequireDefaultQuestions] = useState(true);
  const [deleteModal, setDeleteModal] = useState({ open: false, index: null, type: '' });

  useEffect(() => {
    if (event) {
      setEventName(event.name || '');
      const slugParts = event.slug ? event.slug.split('-') : [];
      setEventSlug(slugParts.slice(0, -1).join('-'));

      const { formattedDate, formattedTime } = formatDateAndTime(event.timestamp);
      setEventDate(formattedDate);
      setEventTime(formattedTime);
      setEventLocation(event.location || '');
      setEventDescription(event.description || '');
      setSpeakersUpdated(event.speakers_updated || []);
      setJudgesUpdated(event.judges_updated || []);
      setSponsors(event.sponsors || []);
      setCohosts(event.cohosts || []);
      setEventImageUrl(event.imageSrc || '');
      setEventType(event.type || 'hackathon');
      setEventAccess(event.access || 'public');
      setQuestions(event.questions ? event.questions.join(', ') : '');
      setRequireDefaultQuestions(event.requireDefaultQuestions);
    }
  }, [event]);

  const resetForm = () => {
    setEventName('');
    setEventSlug('');
    setEventDate('');
    setEventTime('10:00');
    setEventLocation('AGI house, 1868 Floribunda Ave, Hillsborough, CA 94010');
    setEventDescription('');
    setSpeakersUpdated([]);
    setJudgesUpdated([]);
    setSponsors([]);
    setCohosts([]);
    setEventImageUrl('');
    setEventType('hackathon');
    setEventAccess('public');
    setQuestions('');
    setRequireDefaultQuestions(true);
  };

  const handleSaveEvent = async (eventData: Event) => {
    if (event) {
      await updateEvent(eventData);
    } else {
      await createEvent(eventData);
    }
    resetForm();
    onClose();
  };

  const handleAddSponsor = () => {
    setSponsors([
      ...sponsors,
      { name: '', sponsorURL: '', description: '', imageSrc: '' },
    ]);
  };

  const handleAddCohost = () => {
    setCohosts([
      ...cohosts,
      { name: '', cohostURL: '', description: '', imageSrc: '' },
    ]);
  };

  const handleAddJudge = () => {
    setJudgesUpdated([
      ...judgesUpdated,
      { name: '', userID: '', title: '' },
    ]);
  };

  const handleAddSpeaker = () => {
    setSpeakersUpdated([
      ...speakersUpdated,
      { name: '', userID: '', title: '' },
    ]);
  };

  const handleDelete = (index: number, type: string) => {
    setDeleteModal({ open: true, index, type });
  };

  const confirmDelete = () => {
    if (deleteModal.type === 'sponsor') {
      setSponsors(sponsors.filter((_, i) => i !== deleteModal.index));
    } else if (deleteModal.type === 'cohost') {
      setCohosts(cohosts.filter((_, i) => i !== deleteModal.index));
    } else if (deleteModal.type === 'judge') {
      setJudgesUpdated(judgesUpdated.filter((_, i) => i !== deleteModal.index));
    } else if (deleteModal.type === 'speaker') {
      setSpeakersUpdated(speakersUpdated.filter((_, i) => i !== deleteModal.index));
    }
    setDeleteModal({ open: false, index: null, type: '' });
  };

  const handleSponsorChange = (
    index: number,
    field: keyof Sponsor,
    value: string
  ) => {
    const updatedSponsors = [...sponsors];
    updatedSponsors[index][field] = value;
    setSponsors(updatedSponsors);
  };

  const handleCohostChange = (
    index: number,
    field: keyof Cohost,
    value: string
  ) => {
    const updatedCohosts = [...cohosts];
    updatedCohosts[index][field] = value;
    setCohosts(updatedCohosts);
  };

  const handleJudgeChange = (
    index: number,
    field: keyof EventPerson,
    value: string
  ) => {
    const updatedJudges = [...judgesUpdated];
    updatedJudges[index][field] = value;
    setJudgesUpdated(updatedJudges);
  };

  const handleSpeakerChange = (
    index: number,
    field: keyof EventPerson,
    value: string
  ) => {
    const updatedSpeakers = [...speakersUpdated];
    updatedSpeakers[index][field] = value;
    setSpeakersUpdated(updatedSpeakers);
  };

  const handleSubmit = async () => {
    if (!eventDate) {
      alert('Please select an event date.');
      return;
    }
    if (!eventTime) {
      alert('Please select an event start time.');
      return;
    }
    if (!eventSlug) {
      alert('Please add an event slug.');
      return;
    }
    if (!eventName) {
      alert('Please add an event name.');
      return;
    }

  // Convert the input date and time to UTC
  const combinedDateTime = toUTCDate(eventDate, eventTime);
  const formattedDate = DateTime.fromJSDate(combinedDateTime).toFormat('yyyyMMdd');
  const finalSlug = `${eventSlug}-${formattedDate}`;

    const newEvent: Event = {
      id: event ? event.id : uuidv4(),
      name: eventName,
      slug: finalSlug,
      description: eventDescription,
      date: format(combinedDateTime, 'MMMM do, yyyy'),
      timestamp: combinedDateTime,
      location: eventLocation,
      imageSrc: eventImageUrl,
      sponsors,
      cohosts,
      judges: [],
      judges_updated: judgesUpdated,
      speakers: [],
      speakers_updated: speakersUpdated,
      projects: event ? event.projects : [],
      questions: questions
        ? questions.split(',').map((question) => question.trim())
        : [],
      access: eventAccess,
      type: eventType,
      applications: event ? event.applications : [],
      requireDefaultQuestions: requireDefaultQuestions,
    };
    handleSaveEvent(newEvent);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="md:max-w-4xl">
          <DialogHeader>
            <DialogTitle>{event ? 'Update Event' : 'Create Event'}</DialogTitle>
          </DialogHeader>
          <div className="space-y-8">
            <div className="space-y-2">
              <p className="text-agiLightGray">
                Fill out the form below to {event ? 'update' : 'create'} an
                event.
              </p>
            </div>
            <div className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="event-name">Name</Label>
                  <Input
                    id="event-name"
                    placeholder="Event Name"
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="event-slug">Slug</Label>
                  <Input
                    id="event-slug"
                    placeholder="event-slug"
                    value={eventSlug}
                    onChange={(e) => setEventSlug(e.target.value)}
                  />
                </div>
               
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                
                <div className="space-y-2">
                <Label htmlFor="event-date">Date</Label>
                <Input
                  id="event-date"
                  type="date"
                  value={eventDate}
                  onChange={(e) => setEventDate(e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="event-time">Start Time</Label>
                <Input
                  id="event-time"
                  type="time"
                  value={eventTime}
                  onChange={(e) => setEventTime(e.target.value)}
                />
              </div>
              
              </div>
              <div className="space-y-2">
                <Label htmlFor="event-date">Location</Label>
                <Input
                    id="event-location"
                    placeholder="event-location"
                    value={eventLocation}
                    onChange={(e) => setEventLocation(e.target.value)}
                  />
              </div>
              
              
              {/* <div className="space-y-2">
                <Label htmlFor="event-date">Event Date</Label>
                <div className="flex flex-col justify-center items-center w-full">
                  <Calendar
                    mode="single"
                    selected={eventDate}
                    className=""
                    onSelect={setEventDate}
                  />
                  {eventDate && (
                    <p className="mt-2 text-sm text-white">
                      Selected Date:{' '}
                      <span className="font-bold">
                        {format(eventDate, 'MMM do, yyyy')}
                      </span>
                    </p>
                  )}
                </div>
              </div> */}
              <div className="space-y-2">
                <Label htmlFor="event-description">Description</Label>
                <Textarea
                  id="event-description"
                  placeholder="Enter event description"
                  value={eventDescription}
                  onChange={(e) => setEventDescription(e.target.value)}
                  className="min-h-[300px]"
                />
              </div>
              <div className="space-y-2 flex flex-col">
                <Label htmlFor="event-speakers-updated">Speakers</Label>
                {speakersUpdated.map((speaker, index) => (
                  <div key={index} className="bg-agiDarkGray p-4 rounded-md">
                    <div className="space-y-2">
                      <div className="flex gap-4">
                        <Input
                          placeholder="Speaker Name"
                          value={speaker.name}
                          onChange={(e) =>
                            handleSpeakerChange(index, 'name', e.target.value)
                          }
                        />
                        <button
                          className="text-agiLightGray hover:text-white"
                          onClick={() => handleDelete(index, 'speaker')}
                        >
                          <FaTrash></FaTrash>
                        </button>
                      </div>

                      <Input
                        placeholder="Speaker User ID"
                        value={speaker.userID}
                        onChange={(e) =>
                          handleSpeakerChange(index, 'userID', e.target.value)
                        }
                      />
                      <Input
                        placeholder="Speaker Title"
                        value={speaker.title}
                        onChange={(e) =>
                          handleSpeakerChange(index, 'title', e.target.value)
                        }
                      />
                    </div>
                  </div>
                ))}
                <Button variant="outline" className="w-fit" onClick={handleAddSpeaker}>
                  Create Speaker
                </Button>
              </div>
              <div className="space-y-2 flex flex-col">
                <Label htmlFor="event-judges-updated">Judges</Label>
                {judgesUpdated.map((judge, index) => (
                  <div key={index} className="bg-agiDarkGray p-4 rounded-md">
                    <div className="space-y-2">
                      <div className="flex gap-4">
                        <Input
                          placeholder="Judge Name"
                          value={judge.name}
                          onChange={(e) =>
                            handleJudgeChange(index, 'name', e.target.value)
                          }
                        />
                        <button
                          className="text-agiLightGray hover:text-white"
                          onClick={() => handleDelete(index, 'judge')}
                        >
                          <FaTrash></FaTrash>
                        </button>
                      </div>
                      <Input
                        placeholder="Judge User ID"
                        value={judge.userID}
                        onChange={(e) =>
                          handleJudgeChange(index, 'userID', e.target.value)
                        }
                      />
                      <Input
                        placeholder="Judge Title"
                        value={judge.title}
                        onChange={(e) =>
                          handleJudgeChange(index, 'title', e.target.value)
                        }
                      />
                    </div>
                  </div>
                ))}
                <Button variant="outline" className="w-fit" onClick={handleAddJudge}>
                  Create Judge
                </Button>
              </div>
              <div className="space-y-2 flex flex-col">
                <Label htmlFor="event-sponsors">Sponsors</Label>
                {sponsors.map((sponsor, index) => (
                  <div key={index} className="bg-agiDarkGray p-4 rounded-md">
                    <div className="space-y-2">
                      <div className="flex gap-4">
                        <Input
                          placeholder="Sponsor Name"
                          value={sponsor.name}
                          onChange={(e) =>
                            handleSponsorChange(index, 'name', e.target.value)
                          }
                        />
                        <button
                          className="text-agiLightGray hover:text-white"
                          onClick={() => handleDelete(index, 'sponsor')}
                        >
                          <FaTrash></FaTrash>
                        </button>
                      </div>

                      <Input
                        placeholder="Sponsor URL"
                        value={sponsor.sponsorURL}
                        onChange={(e) =>
                          handleSponsorChange(index, 'sponsorURL', e.target.value)
                        }
                      />
                      <Textarea
                        placeholder="Sponsor Description"
                        value={sponsor.description}
                        onChange={(e) =>
                          handleSponsorChange(index, 'description', e.target.value)
                        }
                      />
                      <Input
                        placeholder="Sponsor Image URL"
                        value={sponsor.imageSrc}
                        onChange={(e) =>
                          handleSponsorChange(index, 'imageSrc', e.target.value)
                        }
                      />
                    </div>
                  </div>
                ))}
                <Button variant="outline" className="w-fit" onClick={handleAddSponsor}>
                  Create Sponsor
                </Button>
              </div>
              <div className="space-y-2 flex flex-col">
                <Label htmlFor="event-cohosts">Cohosts</Label>
                {cohosts.map((cohost, index) => (
                  <div key={index} className="bg-agiDarkGray p-4 rounded-md">
                    <div className="space-y-2">
                      <div className="flex gap-4">
                        <Input
                          placeholder="Cohost Name"
                          value={cohost.name}
                          onChange={(e) =>
                            handleCohostChange(index, 'name', e.target.value)
                          }
                        />
                        <button
                          className="text-agiLightGray hover:text-white"
                          onClick={() => handleDelete(index, 'cohost')}
                        >
                          <FaTrash></FaTrash>
                        </button>
                      </div>

                      <Input
                        placeholder="Cohost URL"
                        value={cohost.cohostURL}
                        onChange={(e) =>
                          handleCohostChange(index, 'cohostURL', e.target.value)
                        }
                      />
                      <Textarea
                        placeholder="Cohost Description"
                        value={cohost.description}
                        onChange={(e) =>
                          handleCohostChange(index, 'description', e.target.value)
                        }
                      />
                      <Input
                        placeholder="Cohost Image URL"
                        value={cohost.imageSrc}
                        onChange={(e) =>
                          handleCohostChange(index, 'imageSrc', e.target.value)
                        }
                      />
                    </div>
                  </div>
                ))}
                <Button variant="outline" className="w-fit" onClick={handleAddCohost}>
                  Create Cohost
                </Button>
              </div>
              <div className="space-y-2">
                <Label htmlFor="event-image">Poster URL (optional)</Label>
                <Input
                  id="event-image"
                  placeholder="Enter event image URL"
                  value={eventImageUrl}
                  onChange={(e) => setEventImageUrl(e.target.value)}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="event-type">Type</Label>
                <Select
                  value={eventType}
                  onValueChange={(value) => setEventType(value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select event type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="hackathon">Hackathon</SelectItem>
                    <SelectItem value="conference">Conference</SelectItem>
                    <SelectItem value="workshop">Workshop</SelectItem>
                    <SelectItem value="meetup">Meetup</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="event-access">Access</Label>
                <Select
                  value={eventAccess}
                  onValueChange={(value) => setEventAccess(value)}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Event Access" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="public">Public</SelectItem>
                    <SelectItem value="unlisted">Unlisted</SelectItem>
                    <SelectItem value="member">Members</SelectItem>
                    <SelectItem value="admin">Admin</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              
              <div className="flex flex-col gap-2 w-full items-start">
                <Label htmlFor="event-questions">Questions</Label>
                <div className='flex gap-2'>
                <input
      type='checkbox'
      className='w-6 h-6 hover:cursor-pointer'
      checked={requireDefaultQuestions}
      onChange={(e) => setRequireDefaultQuestions(e.target.checked)}
    />                <p className='text-agiLightGray'>Require Default Questions</p>
                </div>
                <Textarea
                  id="event-questions"
                  placeholder="Question1, Question2, Question3"
                  value={questions}
                  onChange={(e) => setQuestions(e.target.value)}
                />
              </div>
            </div>
          </div>
          <DialogFooter>
            <div className='w-full flex justify-center items-center'>
            <Button variant="white" className='w-full max-w-sm' onClick={handleSubmit}>
              {event ? 'Update Event' : 'Create Event'}
            </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog open={deleteModal.open} onOpenChange={() => setDeleteModal({ open: false, index: null, type: '' })}>
        <DialogContent className='max-w-sm'>
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-6">
            <p>Are you sure you want to delete this {deleteModal.type}?</p>
            <DialogFooter>
              <Button variant="outline" className='w-full'  onClick={() => setDeleteModal({ open: false, index: null, type: '' })}>
                Cancel
              </Button>
              <Button variant="white" className='w-full' onClick={confirmDelete}>
                Confirm
              </Button>
            </DialogFooter>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateEventDialog;
